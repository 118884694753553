import React from "react"
import Layout from "../components/layout"
import { Card, Container } from "react-bootstrap"

export default function About() {
    return (
        <Layout>
            <Container>
                <h1>About</h1>
            </Container>
            <Container>
                <Card className="bg-dark mb-4">
                    <Card.Body>
                        <Card.Text className="display-3">
                            My name is Troy DeRego.
                        </Card.Text>
                        <Card.Text className="display-3">
                            I try really hard to give a shit.
                        </Card.Text>
                    </Card.Body>
                </Card>
                <Card className="script bg-dark mb-4">
                    <Card.Body>
                        <Card.Text>
                            I played my dad's drums when I was a kid. I got hooked. My friend said I could take his father's acoustic guitar. He planned to learn how to play but gave up. When he discovered the guitar was missing he decided to try again so I had to bring it back.
                        </Card.Text>
                    </Card.Body>
                </Card>
                <Card className="script bg-dark mb-4">
                    <Card.Body>
                        <Card.Text>
                            I convinced my brother to get an electric guitar for his birthday, then took it from his room as soon as he wasn't looking. I played along with records and tried to put my fingers in the right place. Sometimes it sounded good to me.
                        </Card.Text>
                    </Card.Body>
                </Card>
                <Card className="script bg-dark mb-4">
                    <Card.Body>
                        <Card.Text>
                            I asked my friends to start a band. They said they couldn't play anything, so "No." So I put on my headphones and kept playing in my room.
                        </Card.Text>
                    </Card.Body>
                </Card>
                <Card className="script bg-dark mb-4">
                    <Card.Body>
                        <Card.Text>
                            I used two cassette recorders to mix tracks and learn how to put guitar, bass, drums, and vocals together. The first time I did it, I had to take a walk around the block, heart racing, like I had just discovered a new life form.
                        </Card.Text>
                    </Card.Body>
                </Card>
                <Card className="script bg-dark mb-4">
                    <Card.Body>
                        <Card.Text>
                            I grew up in the souless suburbs in a town much like Nutfield. Music was my connection to the rest of the world. I believed in music as a protest, music as a way to change the world, to open eyes, and open hearts. It seemed like nothing could be more important than adding my voice to that cause. I still believe this. That's why I try really hard to give a shit.
                        </Card.Text>
                    </Card.Body>
                </Card>
                <Card className="script bg-dark mb-4">
                    <Card.Body>
                        <Card.Text>
                            If you read this far, you must try to give a shit, as well. Thank you. Do you want to start a band?
                        </Card.Text>
                    </Card.Body>
                </Card>
            </Container>
        </Layout>
    )
}